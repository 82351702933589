<template>
  <b-card>
    <header-table title="خدمات المركز" @create="onAdd" />


    <data-table :pagination="false" ref="estatesTable" :fields="fields" ep="/services">
      <template #cell(type)="row">
        <div>
          {{ detectedType(row.item.type) }}
        </div>
      </template>
      <template #cell(is_available)="row">
        <div>
          {{ row.item.is_available ? "نعم" : "لا" }}
        </div>
      </template>
      <template #cell(action)="row">
        <actions-table
          @delete="onDelete(row.item)"
          @update="onUpdateClicked(row.item)"
        />
      </template>
    </data-table>
    <form-modal
      ref="estateModalOffice"
      :formSchema="estatesFormSchema"
      title="إضافة "
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
    </form-modal>
  </b-card>
</template>
      <script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  VBToggle,
  VBTooltip,
  BCollapse,
  BImg,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import FormInput from "@/components/form-input/index";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormFile from "@/components/form-file/index.vue";
import { mapActions, mapGetters } from "vuex";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import centerService from "../../services.js";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
export default {
  components: {
    BAlert,
    BCard,
    DataTable,
    BButton,
    FormInput,
    BRow,
    BCol,
    FormModal,
    FormFile,
    BCollapse,
    VBTooltip,
    VBToggle,
    BImg,
    BCarousel,
    BCarouselSlide,
    BMediaAside,
    BMediaBody,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    ActionsTable
},
  directives: {
    Ripple,
  },
  data: () => {
    return {
      imageSlide: [],
      staticImages: [],
      fields: [
        { key: "title", label: "الخدمة" ,sortable:true},
        { key: "type", label: "نوع الخدمة" },
        { key: "is_available", label: "هل هي متوفرة" },
        { key: "expected_time", label: "الوقت المتوقع للخدمة" },
        { key: "action", label: "" },
      ],
      activModel: false,
      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            id: "title",
            label: "اسم الخدمة",
            rules: "required",
          },
        },
        type: {
          component: "FormSelect",
          key: "type",
          attrs: {
            id: "type_s",
            label: "نوع الخدمة",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "خدمة النافذة الواحدة", value: "SW" },
              { label: "إجراء", value: "SP" },
              { label: "خدمات المنارة", value: "SM" },
            ],
          },
        },
        is_available: {
          component: "FormSelect",
          key: "is_available",
          attrs: {
            id: "is_available",
            label: "هل هي متوفرة",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "نعم", value: 1 },
              { label: "لا", value: 0 },
            ],
          },
        },
        expected_time: {
          component: "FormInput",
          key: "expected_time",
          attrs: {
            label: "الوقت المتوقع للخدمة",
            rules: "required",
            placeholder: "ساعتين"
          },
        },
      },
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    ...mapActions("manageCenter/manageServices", [
      "updateServices",
      "createServices",
      "deleteServices",
    ]),

    onAdd() {
      this.$refs.estateModalOffice.init({});
      this.activModel = true;
    },
    detectedType(val) {
      return centerService.detectedType(val);
    },

    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        expected_time: form.expected_time,
        type: form.type,
        title: form.title,
        is_available:form.is_available
      };
      this.$refs.estateModalOffice.init(toUpdate);
      this.activModel = true;
    },

    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف النشاط", {
          title: "تأكيد حذف النشاط",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteServices({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onModalConfirmed(form) {
      if (form.id) {
        this.updateServices({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createServices({ data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
  },

  computed: {
    ...mapGetters("manageCenter/manageServices", ["loading", "services"]),
  },
};
</script>
    <style lang="scss" >
.top-m {
  padding-top: 7px;
}
</style>